import { useStore } from 'vuex'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import VIEWS_NAMES from '@/constants/VIEWS_NAMES'
import redirectToOscar from '@/utils/redirectToOscar'
import i18n from '@/i18n'

const useTrainingUtils = () => {
  const store = useStore()
  const route = useRoute()
  const router = useRouter()
  const getPartIndex = (moduleId, partId) => {
    return store.state.training.modules[moduleId].parts.indexOf(partId)
  }

  const currentModule = computed(() => {
    return store.getters['training/getModuleSet']
      .find(module => module.path === route.params.modulePath)
  })

  const currentPart = computed(() => {
    return store.getters['training/getModulePartSet'](currentModule.value.id)
      .find(part => part.path === route.params.partPath)
  })

  const currentStep = computed(() => {
    return currentPart.value.steps.map(stepId => store.state.training.steps[stepId])
      .find(step => step.path === route.params.stepPath)
  })

  const currentStepDatas = computed(() => {
    return store.state.training.stepsDatas[currentStep.value.id]
  })

  const isDataLoaded = computed(() => store.state.training.isDatasLoaded)

  const setStepData = ({ key, data }) => {
    store.dispatch('training/saveStepData', {
      stepId: currentStep.value.id,
      key,
      data
    })
  }

  const currentPartDatas = computed(() => {
    return store.state.training.partsDatas[currentPart.value.id]
  })

  const setPartData = ({ key, data }) => {
    store.dispatch('training/savePartData', {
      partId: currentPart.value.id,
      key,
      data
    })
  }

  const stepFinished = async () => {
    await store.dispatch('training/stepFinished', { stepId: currentStep.value.id })
  }

  const setStepFinished = async () => {
    try {
      await stepFinished()
    } catch (error) {
      saveProgressError(error)
    }
  }

  const nextStep = () => {
    const currentStepIndexInPart = currentPart.value.steps.findIndex(stepId => stepId === currentStep.value.id)
    const currentPartIndexInModule = currentModule.value.parts.findIndex(partId => partId === currentPart.value.id)
    if (currentStepIndexInPart < currentPart.value.steps.length - 1) { // next step in currentPart, go to next step
      const nextStep = store.state.training.steps[currentPart.value.steps[currentStepIndexInPart + 1]]
      router.push({
        name: VIEWS_NAMES.TRAINING,
        params: {
          modulePath: currentModule.value.path,
          partPath: currentPart.value.path,
          stepPath: nextStep.path
        }
      })
      return
    }

    if (currentPartIndexInModule < currentModule.value.parts.length - 1) { // next step not in currentPart => go to next part first step
      const nextPart = store.state.training.parts[currentModule.value.parts[currentPartIndexInModule + 1]]
      router.push({
        name: VIEWS_NAMES.TRAINING,
        params: {
          modulePath: currentModule.value.path,
          partPath: nextPart.path,
          stepPath: store.state.training.steps[nextPart.steps[0]].path
        }
      })
      return
    }
    // next step in next module, go to next module summary page
    // if current module is last module redirect to final test
    const currentModuleIndex = store.state.training.moduleList.findIndex(moduleId => moduleId === currentModule.value.id)
    const nextModuleId = store.state.training.moduleList[currentModuleIndex + 1]
    if (nextModuleId) {
      router.push({
        name: VIEWS_NAMES.TRAINING_SUMMARY,
        params: {
          moduleId: nextModuleId
        }
      })
    } else {
      router.push({ name: VIEWS_NAMES.FINAL_TEST })
    }
  }

  const goToActivity = async () => { // si l'user est confident on passe la partie cours à finished et il peut aller direct a l'activité
    try {
      await store.dispatch('training/stepFinished', { stepId: currentStep.value.id })
      await store.dispatch('training/stepFinished', { stepId: currentPart.value.steps[1] })
    } catch (error) {
      saveProgressError(error)
      return
    }
    router.push({
      name: VIEWS_NAMES.TRAINING,
      params: {
        modulePath: currentModule.value.path,
        partPath: currentPart.value.path,
        stepPath: store.state.training.steps[currentPart.value.steps[2]].path
      }
    })
  }

  const goToNextStep = async () => {
    try {
      await stepFinished()
    } catch (error) {
      saveProgressError(error)
      return
    }
    nextStep()
  }

  const saveProgressError = (error) => {
    console.error('Error during progress save : ' + error)
    if (error.response.status === 401) {
      router.push({ name: VIEWS_NAMES.ERROR_TOKEN })
    } else {
      store.dispatch('notification/notify', {
        type: 'error',
        message: i18n.global.t('global.errors.progressSaveError', { errorMessage: error }),
        delay: -1
      }, { root: true })
    }
  }

  const resumeLater = async () => {
    try {
      await stepFinished()
    } catch (error) {
      saveProgressError(error)
      return
    }
    redirectToOscar()
  }

  return {
    currentModule,
    currentPart,
    currentStep,
    currentStepDatas,
    currentPartDatas,
    getPartIndex,
    setStepData,
    setStepFinished,
    nextStep,
    setPartData,
    goToActivity,
    goToNextStep,
    resumeLater,
    isDataLoaded,
    saveProgressError
  }
}

export default useTrainingUtils
