<template>
  <div class="app-background-fixed-layout" :class="{ 'is-gradient': enableGradient }" :style="{ backgroundImage: backgroundImages }">
    <slot></slot>
  </div>
</template>

<script>
import { inject } from 'vue'

export default {
  name: 'AppBackgroundFixedLayout',
  setup () {
    const mq = inject('mq')
    return { mq }
  },
  props: {
    backgroundImage: {
      type: String,
      required: false,
      default () {
        return require('@/assets/backgrounds/oscar-bg.jpeg')
      }
    },
    enableGradient: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    backgroundImages () {
      let toReturn = `url(${this.backgroundImage})`
      if (this.enableGradient && !this.mq.tabletMinus) {
        toReturn += ', linear-gradient(90deg, #006da9 50%, rgba(255, 255, 255, 0) 50%)'
      }
      return toReturn
    }
  }
}
</script>

<style lang="scss" scoped>
.app-background-fixed-layout {
  background-color: $c-secondary;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
  background-attachment: fixed;
}

@media (min-width: $bp-tablet) {
  .app-background-fixed-layout {
    &.is-gradient {
      background-color: $c-background;
    }
  }
}
</style>
