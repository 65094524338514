import { defineAsyncComponent } from 'vue'
import { trainingSteps } from '@/data/trainingModules'
import SimpleLoading from '@/components/SimpleLoading'

const getTrainingStepComponents = () => {
  const toReturn = {}
  Object.keys(trainingSteps).forEach((stepId) => {
    toReturn[stepId] = defineAsyncComponent({
      loader: () => import('./' + stepId + '.vue'),
      loadingComponent: SimpleLoading
    })
  })

  return toReturn
}

export default getTrainingStepComponents()
