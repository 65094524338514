<template>
  <div class="training-view-head">
    <div class="left-col">
      <div class="module-name">{{ module.name }}</div>
      <div class="part-label">
        <p class="index">{{ partIndex + 1 }}</p>
        <p class="label">{{ part.label }}</p>
      </div>
    </div>
    <div class="steps-navigator">
      <div v-for="step in part.steps" :key="step" class="step" :class="{ current: isCurrentStep(step), available: isStepAvailable(step) }" @click="goToStep(step)">
        {{ getStepLabel(step) }}
      </div>
    </div>
  </div>
</template>

<script>
import useTrainingUtils from '@/utils/useTrainingUtils'
import VIEWS_NAMES from '@/constants/VIEWS_NAMES'
import { mapState } from 'vuex'
import { computed } from 'vue'

export default {
  name: 'TrainingViewHead',
  props: {
    module: {
      type: Object,
      required: true
    },
    part: {
      type: Object,
      required: true
    },
    step: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const { getPartIndex } = useTrainingUtils()
    const partIndex = computed(() => getPartIndex(props.module.id, props.part.id))
    return { partIndex }
  },
  computed: {
    ...mapState('training', ['steps', 'finishedSteps'])
  },
  methods: {
    getStepLabel (stepId) {
      return this.steps[stepId].label
    },
    isCurrentStep (stepId) {
      return this.step.id === stepId
    },
    isStepAvailable (stepId) {
      const stepIndexInParts = this.part.steps.findIndex(partStepId => partStepId === stepId)
      if (stepIndexInParts === 0) {
        return true
      } else {
        // Une step est available si la step précédente est finished
        return this.finishedSteps.includes(this.part.steps[stepIndexInParts - 1])
      }
    },
    goToStep (stepId) {
      if (this.isStepAvailable(stepId)) {
        this.$router.push({
          name: VIEWS_NAMES.TRAINING,
          params: {
            modulePath: this.module.path,
            partPath: this.part.path,
            stepPath: this.steps[stepId].path
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.training-view-head {
  color: #fff;
  padding: $space-s $space-sm;
  .module-name {
    padding: $space-s $space-m;
    border-radius: $radius-xl;
    display: inline-block;
    background-color: $c-primary;
    color: #fff;
    font-size: $fz-xs;
    font-weight: $fw-l;
    text-transform: uppercase;
  }
  .part-label {
    margin-top: $space-sm;
    display: flex;
    align-items: center;
    .index {
      margin-right: $space-s;
      flex-shrink: 0;
      width: 20px;
      height: 22px;
      border-radius: 10px;
      background-color: $c-primary;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $fz-xs;
      font-weight: $fw-l;
    }
    .label {
      font-family: $ff-big;
      font-size: $fz-ml;
      font-weight: $fw-l;
    }
  }
  .steps-navigator {
    display: flex;
    align-items: center;
    margin-top: $space-sm;
    .step {
      opacity: 0.3;
      font-size: $fz-xs;
      font-weight: $fw-m;
      text-transform: uppercase;
      transition: $color-transition;
      padding: 6px 18px;
      user-select: none;
      & + .step {
        margin-left: $space-s;
      }
      &.current {
        opacity: 1;
        color: $c-text;
        font-weight: $fw-l;
        background-color: #fff;
        border-radius: $radius;
      }
      &.available {
        opacity: 1;
        cursor: pointer;
      }
    }
  }
}

@media (min-width: $bp-tablet) {
  .training-view-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-col {
      display: flex;
      align-items: center;
      .module-name {
        flex-shrink: 0;
      }
    }
    .part-label {
      margin-left: $space-sm;
      margin-top: 0;
    }
    .steps-navigator {
      margin-top: 0;
    }
  }
}
</style>
