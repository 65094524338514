<template>
  <div class="training">
    <div class="head">
      <training-view-head class="head-content" :module="currentModule" :part="currentPart" :step="currentStep"></training-view-head>
    </div>
    <div class="content">
      <component :is="currentTrainingComponent" @step-finished="setStepFinished" @next-step="goToNextStep"></component>
    </div>
  </div>
</template>

<script>
import AppBackgroundFixedLayout from '@/components/layout/AppBackgroundFixedLayout'
import getTrainingStepComponents from '@/components/trainingSteps/getTrainingStepComponents'
import TrainingViewHead from '@/components/training/TrainingViewHead'
import useTrainingUtils from '@/utils/useTrainingUtils'

export default {
  name: 'training',
  components: { TrainingViewHead, AppBackgroundFixedLayout },
  setup () {
    const { currentModule, currentPart, currentStep, setStepFinished, goToNextStep } = useTrainingUtils()
    return { currentModule, currentPart, currentStep, setStepFinished, goToNextStep }
  },
  computed: {
    currentTrainingComponent () {
      return getTrainingStepComponents[this.currentStep.id]
    }
  }
}
</script>

<style lang="scss" scoped>
.training {
  background-color: $c-background;
  display: flex;
  flex-direction: column;
  height: 100%;
  .head {
    background-color: $c-text;
    .head-content {
      max-width: $bp-desktop-big;
      margin: 0 auto;
    }
  }
  .content {
    flex-grow: 1;
  }
}
</style>

<style lang="scss">
.training {
  .content {
    .simple-loader {
      margin: $space-xxl auto;
      .loader-icon {
        svg {
          circle {
            stroke: $c-secondary;
          }
        }
      }
    }
  }
}
</style>
