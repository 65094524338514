<template>
  <div class="simple-loader" :class="color">
    <div class="loader-icon" v-html="require('@/assets/loading.svg?inline')"></div>
  </div>
</template>

<script>
export default {
  name: 'SimpleLoading',
  props: {
    color: {
      type: String,
      required: false,
      default () {
        return 'white'
      },
      validator (value) {
        return ['primary', 'white', 'text'].includes(value)
      }
    }
  }
}
</script>

<style lang="scss">
// loading under content
.simple-loader {
  max-width: 60px;
  .loader-icon {
    svg {
      animation: rotating 1s linear infinite;
      width: 100%;
      circle {
        stroke: #fff;
      }
    }
  }
  &.primary {
    .loader-icon {
      svg {
        circle {
          stroke: $c-primary;
        }
      }
    }
  }
  &.text {
    .loader-icon {
      svg {
        circle {
          stroke: $c-text;
        }
      }
    }
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
